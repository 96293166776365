
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  ExternalProjectDto, InternalProjectDto, AttachmentHostType,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import BudgetItem from "@/views/project/partnerCapitalApplySettlement/components/BudgetItem.vue";
import {computed} from "vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  components: {
    AttachmentsView,
    BudgetItem, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreatePartnerCapitalApplySettlement extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  isInternal!: boolean;
  projectId: number = 0;
  project: ProjectDto[] = [];
  sexList: DataDictionaryDto[] = []
  educationTypeList: DataDictionaryDto[] = [];
  applyList: PartnerCapitalApplyDto[] = [];
  apply: PartnerCapitalApplyDto = {};
  editLinkId: string = "";
  editData: PartnerCapitalApplySettlementDetailCreateOrUpdateDto = {};
  isSettlemented:boolean=false;
  projectList:InternalProjectDto[] = [];
  viewInvoice:boolean=false;
  currentInvoice:string="";

  // 资金预算明细
  partnerCapitalApplyDetails: PartnerCapitalApplySettlementDetailCreateOrUpdateDto[] = [
    {
      purpose: undefined,
      purposeArray: [],
      applyPrice: undefined,
      settlementPrice: undefined,
      remark: undefined,
      otherFund: undefined,
      otherFundSource: undefined,
      invoiceFile: undefined,
    }
  ];

  form: PartnerCapitalApplySettlementCreateOrUpdateDto = {
    id: undefined,
    projectId: undefined,
    partnerCapitalApplyId: undefined,
    applyReason: undefined,
    totalApplyMoney: undefined,
    auditFlowId: undefined,
    partnerCapitalApplySettlementDetails: []
    // partnerCapitalApplySettlementDetails: this.partnerCapitalApplyDetails
  };

  implementStatusItems: any = [];

  get hostTypeFile() {
    return AttachmentHostType.PartnerCapitalApplySettlementDetail;
  }

  created() {
    this.fetchDataDictionary()
    this.getProjectList();
    this.isInternal = this.$route.query.type === 'internal';
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.partnerCapitalApplySettlement
        .get({id: this.dataId})
        .then((res: any) => {
          if(res.partnerCapitalApplySettlementDetails&&res.partnerCapitalApplySettlementDetails.length>0){
            res.partnerCapitalApplySettlementDetails.forEach((s:any)=>{
              s.savedAttachments=s.attachments?.map((x:any) => x.id)
            })
          }


          this.form = {...res};
          api.partnerCapitalApply.getAllApprovedUnSettlementList({isInternal: this.isInternal, projectId: this.form.projectId!})
            .then((res2: PartnerCapitalApplyDto[]) => {
              this.applyList = res2 ?? [];
              if(this.applyList&&this.applyList.length>0){
                let t=this.applyList.find(s=>s.id===res.partnerCapitalApplyId);
                if(t){
                  this.isSettlemented=false;
                }
                else{
                  this.isSettlemented=false;
                }
              }
              else{
                this.isSettlemented=false;
              }
              this.selectApply(this.form.partnerCapitalApplyId!);
            });
        })
    }
  }

  async getProjectList() {
    let projectList = await api.internalProject.getInternalProjectList()
    let settlementList=await api.partnerCapitalApply.getAllApprovedUnSettlementList({isInternal: this.isInternal});
    if(settlementList&&settlementList.length>0){
      let projectIds=settlementList.map(s=>s.projectId!);
      this.projectList=projectList.filter(s=>projectIds.includes(s.projectId!));
    }
  }

  getOtherFundTotal(){
    let money = 0;
    if (this.form.partnerCapitalApplySettlementDetails && this.form.partnerCapitalApplySettlementDetails.length > 0) {
      this.form.partnerCapitalApplySettlementDetails.forEach(s => {
        money += s.otherFund ?? 0;
      })
    }
    return money
  }

  getApplyMoneyTotal(){
    let money=0;
    if(this.form.partnerCapitalApplySettlementDetails&&this.form.partnerCapitalApplySettlementDetails.length>0){
      this.form.partnerCapitalApplySettlementDetails.forEach(s=>{
        money+=s.applyPrice??0;
      })
    }
    return money
  }

  getSettlementMoneyTotal(){
    let money=0;
    if(this.form.partnerCapitalApplySettlementDetails&&this.form.partnerCapitalApplySettlementDetails.length>0){
      this.form.partnerCapitalApplySettlementDetails.forEach(s=>{
        money+=s.settlementPrice??0;
      })
    }
    return money
  }

  selectProject(e: number) {
    this.clearData(e);
    api.partnerCapitalApply.getAllApprovedUnSettlementList({isInternal: this.isInternal, projectId: e})
      .then((res: PartnerCapitalApplyDto[]) => {
        this.applyList = res ?? [];
      });
  }

  selectApply(id: number) {
    api.partnerCapitalApply.get({id: id}).then(res => {
      this.apply = {...res};
    });
  }

  clearData(projectId: number) {
    this.form = {
      id: this.dataId ? this.dataId : 0,
      projectId: projectId,
      partnerCapitalApplyId: undefined,
      applyReason: undefined,
      totalApplyMoney: undefined,
      auditFlowId: undefined,
      partnerCapitalApplySettlementDetails: []
      // partnerCapitalApplySettlementDetails: this.partnerCapitalApplyDetails
    };

    this.implementStatusItems = []
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType', maxResultCount: 1000})
      .then((res: any) => {
        this.educationTypeList = res.items
      })
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  getSummary(param: any) {
    // debugger
    const {columns, data} = param;
    const sums: any = [];
    let total = 0;

    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = '合计';
        return;
      }
      if (!column.property) {
        return
      }
      const values = data.map((item: any) => Number(item[column.property]))


      if (!values.every((value: any) => isNaN(value)) && (index === 5)) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      } else if (values.every((value: any) => isNaN(value)) && (index === 5)) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          return 0;
        }, 0);
      }

      if (!values.every((value: any) => isNaN(value)) && (index === 6)) {
        total = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      }
    });

    this.form.totalApplyMoney = sums[5] = sums[5] - total;
    sums[5] += '元';

    return sums;
  }

  calculateMoney(index: number, row: any) {
    row.budgetQuotation = (row.unitPrice * row.number).toFixed(2);
    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let total: number = 0
    this.form.partnerCapitalApplySettlementDetails?.map((item: any) => {
      total += Number(item.settlementPrice);
    })
  };

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {

        this.form.totalApplyMoney=this.getSettlementMoneyTotal();
        if (this.form!.id) {
          await api.partnerCapitalApplySettlement.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.partnerCapitalApplySettlement.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  newGuid() {
    return uuidv4();
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "项目必选",
        trigger: "blur"
      }
    ],
    reportDate: [
      {
        required: true,
        message: "提交报告日期必选",
        trigger: "blur"
      }
    ]
  };

  handleViewInvoice(row: any) {
    if(row.tempId){
      this.currentInvoice=row.tempId;
    }
    else{
      this.currentInvoice=row.id;
    }

    this.viewInvoice=true;
    // window.open(row.invoiceFile);
  }

  // 新建
  handleCreate() {
    this.editLinkId = this.newGuid();
    this.editData = {
      // id:this.editLinkId,
      purpose: undefined,
      purposeArray: [],
      applyPrice: undefined,
      settlementPrice: undefined,
      remark: undefined,
      otherFund: undefined,
      otherFundSource: undefined,
      invoiceFile: undefined,
      tempId:this.editLinkId
    } as PartnerCapitalApplySettlementDetailCreateOrUpdateDto;
    (this.$refs.editForm as any).form = this.editData;
    (this.$refs.editForm as any).applyList = this.apply.partnerCapitalApplyDetails;
    (this.$refs.editForm as any).editIndex=undefined;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(row: any,index:number) {
    this.editLinkId = row.id!;
    (this.$refs.editForm as any).editIndex=index;
    (this.$refs.editForm as any).applyList = this.apply.partnerCapitalApplyDetails;
    (this.$refs.editForm as any).form = row;
    (this.$refs.editForm as any).show = true;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  handleDelete(index:number){
    this.form.partnerCapitalApplySettlementDetails!.splice(index, 1);
  }

  handelOnSaved(data: any) {
    let res=data.form as PartnerCapitalApplySettlementDetailCreateOrUpdateDto
    res.purpose = res.purposeArray?.join(',')
    if (res.id) {
      if (this.form.partnerCapitalApplySettlementDetails && this.form.partnerCapitalApplySettlementDetails.length > 0) {
        let target = this.form.partnerCapitalApplySettlementDetails.find(s => s.id == res.id);
        if (target) {
          target = res;
        }
      }
    }else if(data.index!=undefined){
      // this.form.partnerCapitalApplySettlementDetails
    }
    else {
      this.form.partnerCapitalApplySettlementDetails = [...this.form.partnerCapitalApplySettlementDetails!, res];
    }


    console.log(11, res);
    console.log('index',data.index)
  }

  getPorposeNames(p: any[]) {
    let names = [] as string[];
    let _this = this;
    if (p) {
      p.forEach(d => {
        let target = _this.apply.partnerCapitalApplyDetails?.find(s => s.id == d);
        if (target) {
          names.push(target.purpose!);
        }
      })
    }

    return names.join(',');
  }
}
