
  import {Component, Ref, Vue} from "vue-property-decorator";
  import {
    AuditFlowScope,
    PartnerCapitalApplyDto,
  } from "@/api/appService";
  import api from "@/api";
  import moment from "moment";
  import ExportWord from "@/components/DownloadWord/index.vue";
  import {AuditBodyDto} from "@/utils/customDto";
  import AuditButtons from "@/components/AuditButtons/index.vue";

  @Component({
    name: "partnerCapitalApplyDetail",
    components:{
      ExportWord,
      AuditButtons
    }
  })
  export default class PartnerCapitalApplyDetail extends Vue {
    @Ref() auditButtons!: AuditButtons;
    dataId?: number;
    detail: PartnerCapitalApplyDto = {};
    styles:string="";
    selector:string="table-detail-world";
    filename:string="合作方资金申请表";
    auditParams: AuditBodyDto = {
      hostId: undefined,
      hostType: AuditFlowScope.PartnerCapitalApply,
      auditFlowId: undefined,
      auditStatus: undefined,
      audit: undefined
    }

    created() {
      this.dataId = Number(this.$route.params.id);
      if (this.dataId) {
        this.fetchDetail();
      }
    }

    // 获取详情
    async fetchDetail() {
      await api.partnerCapitalApply.get({id: this.dataId}).then(res => {
        this.detail = {...res};
        this.auditParams!.hostId = res.id +''
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if(res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit()
        }
      });
    }

    // 返回
    private cancel() {
      this.$router.back();
    }

    fetchData() {
      return api.partnerCapitalApply.audit;
    }

    changeAudit() {
      this.fetchDetail()
    }
  }
