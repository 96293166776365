
import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  PartnerCapitalApplyDto, PartnerCapitalApplySettlementDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadZipFile/index.vue";
import {AuditBodyDto} from "@/utils/customDto";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {ZipFileInput} from "@/utils/htmlToZipFile/commonDto";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "partnerCapitalApplyDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditButtons
  }
})
export default class PartnerCapitalApplySettlementDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: PartnerCapitalApplySettlementDto = {};
  applyDetail: PartnerCapitalApplyDto = {};
  styles: string = "";
  selector: string = "table-detail-world";
  filename: string = "合作方资金申请结算表";
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.PartnerCapitalApplySettlement,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }
  otherFiles: ZipFileInput[] = [];

  viewInvoice: boolean = false;
  currentInvoice: string = "";

  get hostTypeFile() {
    return AttachmentHostType.PartnerCapitalApplySettlementDetail;
  }

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.partnerCapitalApplySettlement.get({id: this.dataId}).then(res => {
      if (res.partnerCapitalApplySettlementDetails && res.partnerCapitalApplySettlementDetails.length > 0) {
        res.partnerCapitalApplySettlementDetails.forEach((s: any) => {
          s.savedAttachments = s.attachments?.map((x: any) => x.id)
        })
      }
      this.detail = {...res};
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }
      api.partnerCapitalApply.get({id: res.partnerCapitalApplyId}).then(r => {
        this.applyDetail = {...r};

        //附件处理
        if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
          this.detail.partnerCapitalApplySettlementDetails.forEach(async s => {
            let purpose = this.getPorposeNames(s.purposeArray!);
            let atts = await api.attachmentService.getAttachments({hostType: this.hostTypeFile, hostId: s.id})
            if (atts && atts.length > 0) {
              atts.forEach(a => {
                this.otherFiles.push({
                  folder: '票据附件',
                  files: [
                    {
                      title: `[${purpose}]用途票据-${a.fileName}`,
                      // title: purpose +a. '用途票据' + this.getExt(s.invoiceFile!),
                      path: a.url!
                    }
                  ]
                })
              })
            }


          })
        }
      })
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.partnerCapitalApplySettlement.audit;
  }

  changeAudit() {
    this.fetchDetail()
  }

  handleViewInvoice(row: any) {
    if (row.tempId) {
      this.currentInvoice = row.tempId;
    } else {
      this.currentInvoice = row.id;
    }

    this.viewInvoice = true;
    // window.open(row.invoiceFile);
  }

  getBudgetQuotationTotalByApplyDetail() {
    let money = 0;
    if (this.applyDetail.partnerCapitalApplyDetails && this.applyDetail.partnerCapitalApplyDetails.length > 0) {
      this.applyDetail.partnerCapitalApplyDetails.forEach(s => {
        money += s.budgetQuotation ?? 0;
      })
    }
    return money
  }

  getOtherFundTotalByApplyDetail() {
    let money = 0;
    if (this.applyDetail.partnerCapitalApplyDetails && this.applyDetail.partnerCapitalApplyDetails.length > 0) {
      this.applyDetail.partnerCapitalApplyDetails.forEach(s => {
        money += s.otherFund ?? 0;
      })
    }
    return money
  }

  getOtherFundTotal() {
    let money = 0;
    if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
      this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
        money += s.otherFund ?? 0;
      })
    }
    return money
  }

  getApplyMoneyTotal() {
    let money = 0;
    if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
      this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
        money += s.applyPrice ?? 0;
      })
    }
    return money
  }

  getSettlementMoneyTotal() {
    let money = 0;
    if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
      this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
        money += s.settlementPrice ?? 0;
      })
    }

    // if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
    //   this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
    //     money -= s.otherFund ?? 0;
    //   })
    // }
    return money
  }

  getSettlementMoneyTotal2() {
    let money = 0;
    if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
      this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
        money += s.settlementPrice ?? 0;
      })
    }

    if (this.detail.partnerCapitalApplySettlementDetails && this.detail.partnerCapitalApplySettlementDetails.length > 0) {
      this.detail.partnerCapitalApplySettlementDetails.forEach(s => {
        money -= s.otherFund ?? 0;
      })
    }
    return money
  }

  getPorposeNames(p: any[]) {
    let names = [] as string[];
    let _this = this;
    if (p) {
      p.forEach(d => {
        let target = _this.applyDetail.partnerCapitalApplyDetails?.find(s => s.id == d);
        if (target) {
          names.push(target.purpose!);
        }
      })
    }

    return names.join(',');
  }

  getExt(url: string) {
    if (url) {
      return url.split('.').pop();
    }
    return "jpg";
  }

}
