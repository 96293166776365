
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplyCreateOrUpdateDto,
  PartnerCapitalApplyDetailCreateOrUpdateDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";

@Component({
  components: {Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreatePartnerCapitalApply extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  isInternal!: boolean;
  projectId: number = 0;
  project: ProjectDto[] = [];
  sexList: DataDictionaryDto[] = []
  educationTypeList: DataDictionaryDto[] = []

  // 资金预算明细
  partnerCapitalApplyDetails: PartnerCapitalApplyDetailCreateOrUpdateDto[] = [
    {
      purpose: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      otherFund: undefined,
      otherFundSource: undefined,
    }
  ];

  form: PartnerCapitalApplyCreateOrUpdateDto = {
    id: undefined,
    projectId: undefined,
    contactName: undefined,
    applyReason: undefined,
    totalApplyMoney: undefined,
    auditFlowId: undefined,
    partnerCapitalApplyDetails: this.partnerCapitalApplyDetails
  };

  implementStatusItems: any = [];

  created() {
    this.fetchDataDictionary()
    this.isInternal = this.$route.query.type === 'internal';
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.partnerCapitalApply
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
        })
    }
  }

  selectProject(e: number) {
    this.clearData(e);

    if (this.isInternal) {
      this.form.contactName = UserModule.getName;
    } else {
      if (!e) return;
      api.externalProject.getDetailByProjectId({projectId: e})
        .then((res: any) => {
          if (res && res.organizationUnit && res.organizationUnit.id) {
            api.organizationUnit.get({id: res.organizationUnit.id})
              .then((res: any) => {
                this.form.contactName = res.principalName;
              })
          }
        });
    }
  }

  clearData(projectId: number) {
    this.form = {
      id: this.dataId ? this.dataId : 0,
      projectId: projectId,
      contactName: undefined,
      applyReason: undefined,
      totalApplyMoney: undefined,
      auditFlowId: undefined,
      partnerCapitalApplyDetails: this.partnerCapitalApplyDetails
    };

    this.implementStatusItems = []
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType', maxResultCount: 1000})
      .then((res: any) => {
        this.educationTypeList = res.items
      })
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  getSummary(param: any) {
    // debugger
    const {columns, data} = param;
    const sums: any = [];
    let total = 0;

    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = '合计';
        return;
      }
      if (!column.property) {
        return
      }
      const values = data.map((item: any) => Number(item[column.property]))


      if (!values.every((value: any) => isNaN(value)) && (index === 5)) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      } else if (values.every((value: any) => isNaN(value)) && (index === 5)) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          return 0;
        }, 0);
      }

      if (!values.every((value: any) => isNaN(value)) && (index === 6)) {
        total = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      }
    });

    this.form.totalApplyMoney = sums[5] = sums[5] - total;
    sums[5] += '元';

    return sums;
  }

  calculateMoney(index: number, row: any) {
    row.budgetQuotation = (row.unitPrice * row.number).toFixed(2);
    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let total: number = 0
    this.form.partnerCapitalApplyDetails?.map((item: any) => {
      total += Number(item.budgetQuotation);
    })
  };

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.partnerCapitalApply.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.partnerCapitalApply.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  //增加项目执行团队及人员分工
  private addRow(r: number) {
    const obj = {
      purpose: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      otherFund: undefined,
      otherFundSource: undefined,
    };

    this.form.partnerCapitalApplyDetails = [...this.form.partnerCapitalApplyDetails!, obj];
  }

  // 删除项目执行团队及人员分工
  private deleteRow(index: number) {
    this.form.partnerCapitalApplyDetails!.splice(index, 1);
  }

  newGuid() {
    return uuidv4();
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "项目必选",
        trigger: "blur"
      }
    ],
    reportDate: [
      {
        required: true,
        message: "提交报告日期必选",
        trigger: "blur"
      }
    ]
  };
}
